import styled from "styled-components";
import { device } from "../utilities/media_sizes";

export const Spacer = styled.div`
  margin-bottom: ${({ size }) => `${size}px`};
`;

export const PageWrapper = styled.div`
  h1 {
    font-size: 18px;
    letter-spacing: 1px;
  }
  p {
    font-size: 12px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    h1 {
      font-size: 26px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

export const CenterOnMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    align-items: normal;
  }
`;

export const ImageWrapper = styled.img`
  width: ${({ mobWidth }) => mobWidth};

  @media ${device.tablet} {
    width: ${({ imgWidth }) => imgWidth};
    height: ${({ imgHeight }) => imgHeight};
  }
`;
