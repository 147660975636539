import React from "react";
import styled from "styled-components";
import { Container, Grid, Icon } from "semantic-ui-react";
import { device } from "../utilities/media_sizes";
import { Spacer } from "./styles";

const FooterWrapper = styled.div`
  border-top: solid 1px lightgrey;
  margin-top: auto;
  height: 50px;
  p {
    color: #999;
  }
  i {
    font-size: 24px;
  }
`;

const FooterContentWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    ${({ justify }) => justify && `justify-content: ${justify}`}
  }
`;

export const FooterComponent = () => {
  return (
    <FooterWrapper>
      <Container>
        <Spacer size="16" />
        <Grid relaxed columns="equal" stackable>
          <Grid.Column>
            <FooterContentWrapper justify="normal">
              <p>© Adams & Neill 2020</p>
            </FooterContentWrapper>
          </Grid.Column>
          <Grid.Column>
            {/* <FooterContentWrapper>
              <p>
                <span>menu</span> | contact us
              </p>
            </FooterContentWrapper> */}
          </Grid.Column>
          <Grid.Column>
            {/* <FooterContentWrapper justify="flex-end">
              <Icon name="facebook square" color="grey" />
              <Icon name="twitter square" color="grey" />
              <Icon name="instagram" color="grey" />
            </FooterContentWrapper> */}
          </Grid.Column>
        </Grid>
      </Container>
    </FooterWrapper>
  );
};
