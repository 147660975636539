export const getSlideShowArray = (data, searchTerm) => {
    const images = data.allGoogleSheetMediaRow.edges;
    // get all images
    const imageArray = images.filter((image) => image.node.imageid.includes(searchTerm));
    let slideshowArray = []
    // filter for each matching imageid - and check if pairs
    imageArray.forEach(({node: {imageid, url}}, i) => {
        const filteredImages = imageArray.filter(
          (x) => x.node.imageid === `${searchTerm}-${i}` || x.node.imageid === `${searchTerm}-mobile-${i}`
        );
        if (filteredImages.length > 1) {
            if (filteredImages[1]) {
                slideshowArray.push({
                    backgroundUrl: filteredImages[0].node.url,
                    mobileBackgroundUrl: filteredImages[1].node.url
                })
            } else {
                slideshowArray.push({
                  backgroundUrl: filteredImages[0].node.url,
                });
            }
        }
    })
    return slideshowArray
}
