import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const fadeInOut = keyframes`
  0% { opacity: 0; animation-timing-function: ease-in; }
  8% { opacity: 1; animation-timing-function: ease-out; }
  17% { opacity: 1 }  
  25% { opacity: 0 }
  100% { opacity: 0 }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: url(${({ backgroundUrl }) => backgroundUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: ${fadeInOut} ${({ animationDuration }) => animationDuration}s linear infinite 0s;
  animation-delay: ${({ delay }) => delay}s;
  ${({ fullscreen, mobileBackgroundUrl }) => fullscreen && `background-image: url(${mobileBackgroundUrl});`};

  @media (orientation: landscape) {
    background-image: url(${({ backgroundUrl }) => backgroundUrl});
  }
`;

const ChildrenWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const Slideshow = ({ fullscreen, images, width, height, children, duration }) => {
  const [animationDuration, setAnimationDuration] = useState(0);
  const [imagesArray, setImagesArray] = useState([]);

  useEffect(() => {
    const durationLength = duration ? duration : 6;
    setAnimationDuration(images.length * durationLength);
    let array = [];
    let delay = 0;
    images.forEach(({ backgroundUrl, mobileBackgroundUrl }, i) => {
      if (i !== 0) {
        delay = delay + durationLength;
      }
      array.push({
        backgroundUrl,
        mobileBackgroundUrl,
        delay,
      });
    });
    setImagesArray(array);
  }, [images, duration]);

  return (
    <Wrapper width={width} height={height}>
      {imagesArray &&
        imagesArray.map(({ mobileBackgroundUrl, backgroundUrl, delay }, i) => {
          return (
            <div key={i}>
              <ImageWrapper
                backgroundUrl={backgroundUrl}
                animationDuration={animationDuration}
                delay={delay}
                fullscreen={fullscreen}
                mobileBackgroundUrl={mobileBackgroundUrl}
              />
            </div>
          );
        })}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};
