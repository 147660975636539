import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { getSlideShowArray } from "../utilities/get_slideshow-array";
import { Slideshow } from "./slideshow";
import { colors } from "../utilities/variables";

const HeaderWrapper = styled.div`
  height: 10vh;
  h1 {
    margin-left: 32px;
    text-transform: uppercase;
    letter-spacing: 6px;
    margin-top: 32px;
    color: ${colors.scarlet};
  }
  p {
    margin-left: 32px;
    letter-spacing: 1px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -10vh;
`;

const LinkWrapper = styled.div`
  align-self: center;
`;

const SiteLink = styled.p`
  font-size: 32px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: ${colors.scarlet};
`;

export const IntroScreen = ({ handleOnClick }) => {
  const data = useStaticQuery(graphql`
    query SlideshowQuery {
      allGoogleSheetMediaRow {
        edges {
          node {
            imageid
            url
          }
        }
      }
    }
  `);

  const images = getSlideShowArray(data, "intro-slideshow");

  return (
    <Slideshow images={images} height="100vh" width="100vw" fullscreen>
      <HeaderWrapper>
        <h1>Adams & Neill</h1>
        <p>Creative content / Communications / Production</p>
      </HeaderWrapper>
      <Wrapper>
        <LinkWrapper>
          <SiteLink onClick={handleOnClick}>Enter Site</SiteLink>
        </LinkWrapper>
      </Wrapper>
    </Slideshow>
  );
};
