import React, { useState, createContext, useEffect } from "react";
import styled from "styled-components"
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Container, Sidebar, Segment } from "semantic-ui-react";
import "semantic-ui-less/semantic.less";
import { colors } from "../utilities/variables";
import Header from "./header";
import { IntroScreen } from "./intro";
import { SidebarWrapper } from "./sidebar";
import { PageWrapper, Spacer } from "./styles";
import { FooterComponent } from "./footer";

export const AppContext = createContext();

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Layout = ({ children, data }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [showSite, setShowSite] = useState();
  const toggleSidebarVisible = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    setShowSite(!!window.sessionStorage.getItem("showSite") || false);
  }, []);

  const state = {
    sidebarVisible: sidebarVisible,
    setSidebarVisible: toggleSidebarVisible,
  };

  const handleShowSite = () => {
    window.sessionStorage.setItem("showSite", "true");
    setShowSite(true);
  };

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <AppContext.Provider value={state}>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: "description", content: "Sample" },
              { name: "keywords", content: "sample, something" },
            ]}
          />
          <Sidebar.Pushable as={Segment}>
            <SidebarWrapper />
            <Sidebar.Pusher dimmed={sidebarVisible} style={{ minHeight: "100vh" }}>
              {!showSite ? (
                <IntroScreen handleOnClick={handleShowSite} />
              ) : (
                <AppWrapper>
                  <Header
                    siteTitle={data.site.siteMetadata.title}
                    backgroundColor={colors.white}
                    headerHeight="60px"
                    border
                    borderColor={colors.scarlet}
                    textColor={colors.scarlet}
                    iconColor={colors.scarlet}
                    headerSpacing="6px"
                    headerTransform="uppercase"
                  />
                  <Spacer size="16" />
                  <Container>
                    <PageWrapper>
                      {children}
                    </PageWrapper>
                  </Container>
                  <FooterComponent />
                </AppWrapper>
              )}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </AppContext.Provider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
