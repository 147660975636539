import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Menu } from "semantic-ui-react";

const StyledMenu = styled(Menu)`
  border: 0 !important;
  text-align: center;
  box-shadow: none !important;
  font-size: 20px !important;
`;

const LinkedItem = ({ children, ...props }) => (
  <Menu.Item as={Link} activeClassName="active" {...props}>
    {children}
  </Menu.Item>
);

export default function MenuWrapper() {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allGoogleSheetMenuPagesRow {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  const menuItems = data.allGoogleSheetMenuPagesRow.edges;
  return (
    <StyledMenu vertical fluid borderless>
      <LinkedItem to="/" exact>
        <p>Home</p>
      </LinkedItem>
      {menuItems.map(({ node: { slug, title } }, i) => (
        <LinkedItem to={`/${slug}`}>{title}</LinkedItem>
      ))}
    </StyledMenu>
  );
}
