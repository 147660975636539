import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container, Icon } from "semantic-ui-react";
import { AppContext } from "./layout";

const Wrapper = styled.div`
  background: ${({ bgColor }) => bgColor};
  ${({ border, borderColor }) => border && `border-bottom: solid 1px ${borderColor};`}
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: ${({ height }) => (height ? height : "60px")};
  h1 {
    a {
      color: ${({ color }) => (color ? color : "#333")};
      text-transform: ${({ textTransform }) => (textTransform ? textTransform : "none")};
      letter-spacing: ${({ textSpacing }) => (textSpacing ? textSpacing : "none")};
    }
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  i {
    color: ${({color}) => color};
    font-size: 30px;
  }
`;

const Header = ({ siteTitle, backgroundColor, headerHeight, border, borderColor, textColor, iconColor, headerSpacing, headerTransform }) => {
  const { setSidebarVisible } = useContext(AppContext);
  return (
    <Wrapper bgColor={backgroundColor} border={border} borderColor={borderColor}>
      <Container>
        <HeaderWrapper height={headerHeight} color={textColor} textSpacing={headerSpacing} textTransform = {headerTransform}>
          <h1 style={{ padding: "1rem 0", marginBottom: "2rem" }}>
            <Link to="/">{siteTitle}</Link>
          </h1>
          <IconWrapper onClick={() => setSidebarVisible()} color={iconColor} >
            <Icon name="bars" />
          </IconWrapper>
        </HeaderWrapper>
      </Container>
    </Wrapper>
  );
};

export default Header;
